import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserManagement from './UserManagement';
import ProviderConfigManagement from './ProviderConfigManagement';
import ModelConfigManagement from './ModelConfigManagement';

import UserPermissions from '../model/userPermissions';

import './AdminTab.less';

const AdminTab: FC<{ permissions: UserPermissions, accessToken: string }> = ({ permissions, accessToken }) => {
  const [task, setTask] = useState('');
  const { t } = useTranslation();

  return (
    <div className="admin-tab">
      <h1 className="tab-heading">{t('admin.tab.title')}</h1>
      <div className="parent">
        <div className="tasks">
          <ul>
            {
              (permissions.canSystemAdmin || permissions.canOrgAdmin)
                && <li onClick={() => setTask('user')}>{t('admin.task.user')}</li>
            }
            {
              /*
              permissions.canSystemAdmin
                && <li onClick={() => setTask('provider')}>{t('admin.task.provider')}</li>
               */
            }
            {
              /*
                (permissions.canSystemAdmin || permissions.canOrgAdmin) // TODO use model config permissions
                && <li onClick={() => setTask('model')}>{t('admin.task.model')}</li>
              */
              }
          </ul>
        </div>
        <div className="content">
          { (task === 'user' && (permissions.canSystemAdmin || permissions.canOrgAdmin)) && <UserManagement permissions={permissions} accessToken={accessToken} /> }
          { (task === 'provider' && permissions.canSystemAdmin) && <ProviderConfigManagement /> }
          { (task === 'model' && (permissions.canSystemAdmin || permissions.canOrgAdmin)) && <ModelConfigManagement permissions={permissions} /> }
        </div>
      </div>
    </div>
  );
};

export default AdminTab;
