import React, { FC, useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import LogoutButton from './component/LogoutButton';
import AdminTab from './AdminTab/AdminTab';
import ReviewTab from './ReviewTab/ReviewTab';
import ManualTab from './ManualTab/ManualTab';
import StatusTab from './StatusTab/StatusTab';
import TestingTab from './TestingTab/TestingTab';
import { getLogin, getPermissions, getProviderId } from './service/permissionService';
import UserIcon from './component/UserIcon/UserIcon';
import EnvName from './component/EnvironmentName/EnvironmentName';

import { EnvironmentConfig } from './model/EnvironmentConfig';
import UserPermissions, { DEFAULT_PERMISSIONS } from './model/userPermissions';

import version from './Version.json';

const App: FC<{ env: EnvironmentConfig }> = ({ env }) => {
  const {
    error, logout, isAuthenticated, getAccessTokenSilently,
  } = useAuth0();
  const [login, setLogin] = useState('');
  const [permissions, setPermissions] = useState<UserPermissions>(DEFAULT_PERMISSIONS);
  const [provider, setProvider] = useState('');
  const [accessToken, setAccessToken] = useState<string>('');
  const { t } = useTranslation();

  if (error || !isAuthenticated) {
    if (isAuthenticated) logout();
    return null;
  }

  useEffect(() => {
    getAccessTokenSilently().then(
      (fetchToken) => {
        setLogin(getLogin(fetchToken));
        setProvider(getProviderId(fetchToken));
        setPermissions(getPermissions(fetchToken));
        setAccessToken(fetchToken);
      },
      (response: any) => {
        message.error('Error occurred ', response);
      },
    );
  }, []);

  return (
    <>
      <div className="banner-info">
        <EnvName name={env.envName} version={version.version} domain={env.domainName} />
        &nbsp;
        &nbsp;
        |
        &nbsp;
        <UserIcon login={login} permissions={permissions.permissions} provider={provider} />
        &nbsp;
        |
        <LogoutButton />
      </div>
      <a href={env.umeUrl} target="_blank" rel="noreferrer">
        <img className="logo" src="assets/images/logos/UnderwriteMe_Logo.png" alt={`${t('logo.title')}`} title={`${t('logo.title')}`} />
      </a>
      <Tabs>
        <div className="tab-bar">
          <TabList>
            { permissions.canOverride && (<Tab>{t('tab.title.review')}</Tab>) }
            { permissions.canOverride && (<Tab>{t('tab.title.manual')}</Tab>) }
            <Tab>{t('tab.title.status')}</Tab>
            { (env.enableTestingTab && (permissions.canCreate || permissions.canAction)) && (<Tab>{t('tab.title.testing')}</Tab>) }
            { (permissions.canOrgAdmin || permissions.canSystemAdmin) && (<Tab>{t('tab.title.admin')}</Tab>) }
          </TabList>
        </div>
        { permissions.canOverride && (
          <TabPanel>
            <ReviewTab accessToken={accessToken} />
          </TabPanel>
        )}
        { permissions.canOverride && (
          <TabPanel>
            <ManualTab accessToken={accessToken} />
          </TabPanel>
        )}
        <TabPanel>
          <StatusTab accessToken={accessToken} />
        </TabPanel>
        { (env.enableTestingTab && (permissions.canCreate || permissions.canAction)) && (
          <TabPanel>
            <TestingTab accessToken={accessToken} env={env} />
          </TabPanel>
        )}
        { (permissions.canOrgAdmin || permissions.canSystemAdmin) && (
          <TabPanel>
            <AdminTab accessToken={accessToken} permissions={permissions} />
          </TabPanel>
        )}
      </Tabs>
    </>
  );
};

export default withAuthenticationRequired(App, {
});
