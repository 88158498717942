interface UserPermissions {

  permissions: string[],
  canOverride: boolean,
  canCreate: boolean,
  canAction: boolean,
  canOrgAdmin: boolean,
  canSystemAdmin: boolean,
  canCreateUser: boolean,
  canUpdateUser: boolean,
  canDeleteUser: boolean,

}

export default UserPermissions;

export const DEFAULT_PERMISSIONS: UserPermissions = {
  permissions: [],
  canOverride: false,
  canCreate: false,
  canAction: false,
  canOrgAdmin: false,
  canSystemAdmin: false,
  canCreateUser: false,
  canDeleteUser: false,
  canUpdateUser: false,
};
