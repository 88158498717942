import React, { FC } from 'react';
import { Button } from 'antd';

//
// Link button
//

const LinkButton: FC<{ title?: string, text: string, url?: string, maxWidth: string | number, onClick?: Function }> = ({
  title = '', text, url = undefined, maxWidth, onClick = undefined,
}) => {
  const handleOnClick = (e: any) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  return (
    <Button
      style={{ maxWidth }}
      className="ellipsis"
      title={title === '' ? text : title}
      type="link"
      href={url}
      target="_blank"
      onClick={(e) => handleOnClick(e)}
    >
      {text}
    </Button>
  );
};

export default LinkButton;
