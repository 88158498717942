import React from 'react';
import { createRoot } from 'react-dom/client';

// import i18n (needs to be bundled ;))
import './service/i18n';

import AppLoader from './AppLoader';

//
// Render the application
//

const container = document.getElementById('app') || document.createElement('div');
const root = createRoot(container);

root.render(<AppLoader />);
