import React, { FC } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

//
// Simple Yes/No dialog box.
// The supplied onExit function will be called with true if yes is clicked and false if not.
//

const YesNoDialog: FC<{ title: string, onExit: (confirmed: boolean) => void, open: boolean, children: any }> = ({
  title, onExit, open, children,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      okText={t('yes.no.dialog.yes')}
      cancelText={t('yes.no.dialog.no')}
      title={title}
      onOk={() => onExit(true)}
      onCancel={() => onExit(false)}
      open={open}
    >
      {children}
    </Modal>
  );
};

export default YesNoDialog;
