import React, { FC, useState } from 'react';
import {
  Button, Descriptions, InputNumber, Select,
} from 'antd';
import { cloneDeep } from 'lodash';

import { Enquiry } from '../../model/enquiry';
import { Product } from '../../model/product';
import { ManualEnquiry } from '../../model/ManualEnquiry';

import './EnquiryDetails.less';

const EnquiryDetails: FC<{
  baseEnquiry: Enquiry | ManualEnquiry, onOverride: (products?: Product[]) => void
}> = ({
  baseEnquiry, onOverride,
}) => {
  const [enquiry, setEnquiry] = useState<Enquiry | ManualEnquiry>(baseEnquiry);

  const overrideData = () => {
    onOverride(enquiry!.products);
  };

  const { Option } = Select;

  return (
    <div className="enquiry-details">
      {
        'email' in baseEnquiry && baseEnquiry.email && 'advisorEmail' in baseEnquiry && baseEnquiry.advisorEmail && (
          <Descriptions column={2}>
            <Descriptions.Item label="Customer Email">{baseEnquiry.email}</Descriptions.Item>
            <Descriptions.Item label="Adviser Email">{baseEnquiry.advisorEmail}</Descriptions.Item>
          </Descriptions>
        )
      }
      <div className="products">
        {
          enquiry?.products.sort((p1, p2) => {
            if (p1.name > p2.name) return 1;
            if (p1.name < p2.name) return -1;
            return 0;
          }).map((product) => (
            <Descriptions className="product" key={product.name} title={product.name} column={1} bordered>
              {product.overrides.sort((o1, o2) => {
                if (o1.name > o2.name) return 1;
                if (o1.name < o2.name) return -1;
                return 0;
              }).map((override) => (
                <Descriptions.Item key={override.name} label={override.name}>
                  { override.name !== 'DECISION' && (
                    <InputNumber
                      style={{ width: 125 }}
                      min="0"
                      defaultValue={override.value}
                      stringMode
                      onChange={(number: string | null) => {
                        // eslint-disable-next-line no-param-reassign
                        if (number === null) override.value = '';
                        // eslint-disable-next-line no-param-reassign
                        else override.value = number;
                        setEnquiry(cloneDeep(enquiry));
                      }}
                    />
                  )}
                  { override.name === 'DECISION' && (
                    <Select
                      style={{ width: 250 }}
                      onChange={(value: string) => {
                        // eslint-disable-next-line no-param-reassign
                        override.value = value;
                        setEnquiry(cloneDeep(enquiry));
                      }}
                    >
                      {enquiry?.overrideOptions.map((option: string) => (
                        <Option key={option}>{option}</Option>
                      ))}
                    </Select>
                  )}
                </Descriptions.Item>
              ))}
            </Descriptions>
          ))
        }
      </div>
      <div className="buttons">
        <Button onClick={() => onOverride()}>Cancel</Button>
        <Button type="primary" disabled={!enquiry} onClick={overrideData}>Override</Button>
      </div>
    </div>
  );
};

export default EnquiryDetails;
