import React, { FC, useMemo, useState } from 'react';
import { IconContext } from 'react-icons';
import { IoPersonOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import OkDialog from '../OkDialog';

import './UserIcon.less';

/**
 * Display a user icon that provides a mouse-over and pop-up that shows the user's login and roles.
 * @param login - username
 * @param permissions - array of user permissions
 * @param provider - medical provider
 */
const UserIcon: FC<{
  login: string, permissions: string[], provider: string
}> = ({ login, permissions, provider }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  const tab = '\t';
  const newLine = '\n';

  const loginText = t('user.icon.login');
  const providerText = t('user.icon.provider');
  const permissionsTtext = t('user.icon.permissions');
  const perms = permissions.join(`${newLine}${tab}${tab}${tab}`);
  const details = `${loginText}${tab}${tab}${login}${newLine}${newLine}${providerText}${tab}${tab}${provider}${newLine}${newLine}${permissionsTtext}${tab}${perms}`;
  const iconValue = useMemo(() => ({ size: '1.5em' }), []);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  return (
    <div className="user-icon">
      <div className="icon-wrapper">
        <IconContext.Provider value={iconValue}>
          <IoPersonOutline title={details} onClick={openDialog} />
        </IconContext.Provider>
      </div>
      <OkDialog
        open={dialogOpen}
        title={t('user.icon.title')}
        onExit={closeDialog}
      >
        <table className="user-icon-dialog">
          <tbody>
            <tr>
              <th>{loginText}</th>
              <td>{login}</td>
            </tr>
            <tr>
              <th>{providerText}</th>
              <td>{provider}</td>
            </tr>
            <tr>
              <th>{permissionsTtext}</th>
              <td>
                {
                  permissions.map((p) => <div key={p}>{p}</div>)
                }
              </td>
            </tr>
          </tbody>
        </table>
      </OkDialog>
    </div>
  );
};

export default UserIcon;
