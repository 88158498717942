import axios, { AxiosResponse } from 'axios';

import { APIEnquiry, ManualAPIEnquiry } from '../enquiryApi';
import { getProviderId } from '../permissionService';

import { Command, IdWrapper } from '../../model/command';
import { Enquiry, Enquiries } from '../../model/enquiry';
import { Product } from '../../model/product';
import { ManualEnquiry, ManualEnquiries } from '../../model/ManualEnquiry';

export interface GetEnquiryParams {
  page: number;
  pageSize: number;
  sortField?: string;
  sortOrder?: string;
  filterField?: string;
  filterValue?: string;
  excludeFilter?: boolean,
}

const format = (enq: APIEnquiry | ManualAPIEnquiry): Enquiry | ManualEnquiry => {
  const products = enq.products.reduce((result, product) => {
    const existing = result.find((prod) => prod.name === product.name);
    if (existing) {
      existing.overrides.push(product.overrides);
      return result;
    }
    return result.concat({
      name: product.name,
      overrides: [product.overrides],
    });
  }, [] as Product[]);
  return {
    ...enq,
    products,
  };
};

/* eslint-disable no-param-reassign */
const getEnquiryDetails = (url: string, token: string, requestParams: GetEnquiryParams): Promise<AxiosResponse<Enquiries>> => {
  const providerId: string = getProviderId(token);

  return axios.get<Enquiries>(url, {
    headers: {
      'X-ProviderId': providerId, // only for local testing, real users are extracted from token
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: requestParams.pageSize,
      offset: requestParams.pageSize * (requestParams.page - 1),
      sortBy: requestParams.sortOrder ? requestParams.sortField : null,
      sortDirection: requestParams.sortOrder,
      filterField: requestParams.filterField || null,
      filterValue: requestParams.filterValue || null,
      excludeFilter: requestParams.excludeFilter || null,
    },
    transformResponse: (rawData : string) => {
      const data = JSON.parse(rawData);
      return { ...data, data: data.data.map(format) };
    },
  }).then((res: AxiosResponse<Enquiries>) => {
    res.data.data.forEach((data) => {
      data.firstRequestedDate = new Date(data.firstRequestedDate);
      data.enquiryId = data.enquiryId || '';
      data.applicationId = data.applicationId || '';
      data.firstName = data.firstName || '';
      data.surname = data.surname || '';
      data.evidenceProviders.forEach((provider) => {
        provider.bookingStatusUpdated = new Date(provider.bookingStatusUpdated);
        provider.evidenceRequested = provider.evidenceRequested || '';
        provider.evidenceProvider = provider.evidenceProvider || '';
        provider.bookingStatus = provider.bookingStatus || '';
      });
      data.actioned = false;
    });
    return res;
  });
};

export const getOverrides = (
  token: string,
  requestParams: GetEnquiryParams,
): Promise<AxiosResponse<Enquiries>> => getEnquiryDetails('/sg/v2/overrides', token, requestParams);

export const getEnquiries = (
  token: string,
  requestParams: GetEnquiryParams,
): Promise<AxiosResponse<Enquiries>> => getEnquiryDetails(
  '/sg/v2/enquiries',
  token,
  {
    ...requestParams,
    // This relies on the status and testing tables not having their own filters
    filterField: 'bookingStatus',
    filterValue: 'INSUFFICIENT_DETAILS',
    excludeFilter: true,
  },
);

export const overrideEnquiry = (
  enquiryId: string,
  providerId: string,
  products: Product[],
  token: string,
): Promise<AxiosResponse<string>> => {
  const command: Command = {
    enquiryId: {
      id: enquiryId,
    },
    providerId: {
      id: providerId,
    },
    decisionDetails: {},
  };

  products.forEach((product) => {
    product.overrides.forEach((override) => {
      const key = `${override.name}_${product.name}`;
      command.decisionDetails[key] = override.value;
    });
  });

  return axios.post(
    `/oc/v1/override/${enquiryId}`,
    command,
    {
      headers: {
        'X-ProviderId': providerId,
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

//
// Get the transaction ID for the given enquiry ID
//
export const getTransactionForEnquiry = (token: string, enquiryId: string): Promise<AxiosResponse<IdWrapper>> => {
  const providerId: string = getProviderId(token);

  return axios.get(
    `/sg/v1/transactions/enquiry/${enquiryId}`,
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .catch((error: any) => Promise.reject(new Error(`Error retrieving transaction ID for enquiry ID: ${error}`)));
};

//
// Get manual review enquiries
//
export const getManualOverrides = (token: string, requestParams: GetEnquiryParams): Promise<AxiosResponse<ManualEnquiries>> => {
  const url = '/sg/v2/overrides/manual';
  const providerId: string = getProviderId(token);

  return axios.get<ManualEnquiries>(url, {
    headers: {
      'X-ProviderId': providerId, // only for local testing, real users are extracted from token
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: requestParams.pageSize,
      offset: requestParams.pageSize * (requestParams.page - 1),
      sortBy: requestParams.sortOrder ? requestParams.sortField : null,
      sortDirection: requestParams.sortOrder,
      filterField: requestParams.filterField || null,
      filterValue: requestParams.filterValue || null,
      excludeFilter: requestParams.excludeFilter || null,
    },
    transformResponse: (rawData: string) => {
      const data = JSON.parse(rawData);
      return { ...data, data: data.data.map(format) };
    },
  })
    .then((res: AxiosResponse<ManualEnquiries>) => {
      res.data.data.forEach((data) => {
        data.firstRequestedDate = new Date(data.firstRequestedDate);
        data.enquiryId = data.enquiryId || '';
        data.applicationId = data.applicationId || '';
        data.firstName = data.firstName || '';
        data.surname = data.surname || '';
        data.evidenceProviders.forEach((provider) => {
          provider.bookingStatusUpdated = new Date(provider.bookingStatusUpdated);
          provider.evidenceRequested = provider.evidenceRequested || '';
          provider.evidenceProvider = provider.evidenceProvider || '';
          provider.bookingStatus = provider.bookingStatus || '';
        });
        data.actioned = false;
      });
      return res;
    });
};
