import axios, { AxiosResponse } from 'axios';
import { getProviderId } from '../permissionService';
import { ReferralResponse } from '../../model/referralResponse';

export const createReferral = (
  token: string,
  user: string,
  applicationId: string,
  enquiryId: string,
  forename: string,
  surname: string,
): Promise<AxiosResponse<ReferralResponse>> => {
  const providerId: string = getProviderId(token);

  return axios.post<ReferralResponse>(
    '/ec/v1/referrals/',
    {
      providerId,
      applicationId,
      enquiryId,
      customer: {
        gender: 'MALE',
        title: 'MR',
        firstName: forename,
        surname,
        dateOfBirth: 2000,
        address: {
          houseNumberName: '14',
          street: 'The Drive',
          townCity: 'Durham',
          postcode: 'D15ER',
        },
        email: 'abc.bond@hsbc.co.uk',
        phoneNumber: '07554232332',
      },
      advisor: {
        email: 'james.scobbie@underwriteme.co.uk',
      },
      redirectURL: null,
    },
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
