import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

//
// Simple OK dialog box.
// The supplied onExit function will be called when the dialog is closed.
//

const OkDialog: FC<{ title: string, onExit: () => void, open: boolean, children: any }> = ({
  title, onExit, open, children,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      open={open}
      footer={[
        <Button onClick={onExit} type="primary" key="ok">{t('ok.dialog.ok')}</Button>,
      ]}
      onCancel={onExit}
    >
      {children}
    </Modal>
  );
};

export default OkDialog;
