import React from 'react';
import { Button, Space } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

const LogoutButton = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation();

  return (
    <Space align="start" style={{ float: 'right' }}>
      <Button type="link" onClick={() => logout({ returnTo: window.location.origin })}>{t('logout.button.text')}</Button>
    </Space>
  );
};

export default LogoutButton;
