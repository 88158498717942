import React, {
  BaseSyntheticEvent, FC, useEffect, useState,
} from 'react';
import {
  Button, Input, message, Modal, Select, Space, Spin,
} from 'antd';
import { BaseOptionType } from 'rc-select/lib/Select';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { EnquiryOverrides } from '../../model/EnquiryOverrides';
import { getLogin } from '../../service/permissionService';
import { getMedicalEvidenceProviders } from '../../service/TestingService';

import './NewTestCaseDialog.less';

interface ProductOption {
  product: string,
  chosen: boolean
}

const medicalProducts: string[] = [
  'BIOCHEM',
  'BLOOD',
  'ECG',
  'HIV',
  'LIPIDS',
  'MEDICAL',
  'PARA',
  'PSA',
  'COTININE',
  'MSU',
  'LFT',
  'PFT',
  'HBA1C',
  'HEP_BC',
  'MINI_SCREEN',
  'DRUG_SCREEN',
  'GLUCOSE',
  'URINALYSIS',
  'UREA',
  'RECG',
  'HBSAG',
  'HCV',
  'FBG',
  'HAEM',
  'RBG',
  'DIABETIC_MINI_SCREEN',
];

//
// Create a new enquiry.
// The supplied onExit function will be called with the selected provider and products if yes is clicked, and false if not.
//

const NewTestCaseDialog: FC<{
  onExit: (response: EnquiryOverrides | boolean, forename: string, surname: string, branch: string, tag: string) => void,
  open: boolean,
  branch: string,
  tag: string
}> = ({
  onExit, open, branch, tag,
}) => {
  const [provider, setProvider] = useState<string | null>(null);
  const [products, setProducts] = useState<ProductOption[]>([]);
  const [forename, setForename] = useState('');
  const [surname, setSurname] = useState('');
  const [defaultForename, setDefaultForename] = useState('');
  const [defaultSurname, setDefaultSurname] = useState('');
  const [loading, setLoading] = useState(false);
  const [medicalProviders, setMedicalProviders] = useState<BaseOptionType[]>([]);
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();

  //
  // Render the defined medical exam product types as a grid
  //
  const renderProducts = ():JSX.Element => {
    const cols = 2;

    const handleProductSelection = (event: BaseSyntheticEvent): void => {
      if (event?.target != null) {
        const chosenProducts = products.map((p) => {
          // eslint-disable-next-line no-param-reassign
          if (p.product === event.target.id) p.chosen = !p.chosen;
          return p;
        });
        setProducts(chosenProducts);
      }
    };

    const rows: JSX.Element[] = [];
    let row: JSX.Element[] = [];
    let i: number;
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < products.length; i++) {
      if (i % cols === 0) {
        if (i > 0) rows.push(<tr key={i}>{row}</tr>);
        row = [];
      }
      row.push(
        <td key={products[i].product}>
          <Space>
            <label htmlFor={products[i].product}>{products[i].product}</label>
            <input type="checkbox" id={products[i].product} onChange={handleProductSelection} checked={products[i].chosen} />
          </Space>
        </td>,
      );
    }
    if (row.length > 0) {
      rows.push(<tr key={i}>{row}</tr>);
    }
    return <table className="new-case-dialog-new-case-products-table"><tbody>{rows}</tbody></table>;
  };

  const setSortedProducts = () => {
    const sortedProducts = medicalProducts
      .map((p) => ({ product: p, chosen: false }))
      .sort((a, b) => a.product.localeCompare(b.product));
    setProducts(sortedProducts);
  };

  const capitalise = (word: string): string => word[0].toUpperCase() + word.substring(1).toLowerCase();

  const extractName = (token: string): void => {
    const login = getLogin(token);
    const parts = login.split('@');
    if (parts.length > 0) {
      const names = parts[0].split('.');
      if (names.length > 1) {
        setForename(capitalise(names[0]));
        setDefaultForename(capitalise(names[0]));
        setSurname(capitalise(names[1]));
        setDefaultSurname(capitalise(names[1]));
      }
    }
  };

  const getMedicalProviders = (token: string): void => {
    setLoading(true);
    getMedicalEvidenceProviders(token, branch, tag)
      .then((providers: BaseOptionType[]) => setMedicalProviders(providers))
      .catch((error: any) => message.error(`${t('new.case.dialog.get.providers.error')} ${error}`))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setSortedProducts();
    getAccessTokenSilently().then((token) => {
      extractName(token);
      getMedicalProviders(token);
    });
  }, []);

  const handleReset = () => {
    setProvider(null);
    setSortedProducts();
    setForename(defaultForename);
    setSurname(defaultSurname);
  };

  const handleOk = (): void => {
    const selectedProducts: string[] = [];
    products.forEach((product) => {
      if (product.chosen) selectedProducts.push(product.product);
    });
    const chosenProvider = (provider == null) ? '' : provider;
    handleReset();
    onExit({ providers: [chosenProvider], products: selectedProducts }, forename, surname, branch, tag);
  };

  const handleExit = (): void => {
    handleReset();
    onExit(false, '', '', '', '');
  };

  const okEnabled = (): boolean => {
    let productCount = 0;
    // eslint-disable-next-line no-plusplus
    products.forEach((product) => { if (product.chosen) productCount++; });
    return provider != null && productCount > 0 && forename.length > 0 && surname.length > 0;
  };

  return (
    <Modal
      title={t('new.case.dialog.title')}
      destroyOnClose
      onOk={handleOk}
      onCancel={handleExit}
      open={open}
      footer={[
        <Button key="reset" title={`${t('new.case.dialog.button.reset.title')}`} onClick={handleReset}>
          {t('new.case.dialog.button.reset')}
        </Button>,
        <Button key="no" title={`${t('new.case.dialog.button.no.title')}`} onClick={handleExit}>
          {t('new.case.dialog.button.no')}
        </Button>,
        <Button key="yes" type="primary" title={`${t('new.case.dialog.button.yes.title')}`} onClick={handleOk} disabled={!okEnabled()}>
          {t('new.case.dialog.button.yes')}
        </Button>,
      ]}
    >
      {t('new.case.dialog.text')}
      <table className="new-case-dialog-new-case-table">
        <tbody>
          <tr>
            <th>
              <label htmlFor="new-case-dialog-new-case-forename" className="mandatory">{t('new.case.dialog.forename')}</label>
            </th>
            <td>
              <Input
                id="new-case-dialog-new-case-forename"
                value={forename}
                placeholder={`${t('new.case.dialog.forename.prompt')}`}
                onChange={(event) => setForename(event.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>
              <label htmlFor="new-case-dialog-new-case-surname" className="mandatory">{t('new.case.dialog.surname')}</label>
            </th>
            <td>
              <Input
                id="new-case-dialog-new-case-surname"
                value={surname}
                placeholder={`${t('new.case.dialog.surname.prompt')}`}
                onChange={(event) => setSurname(event.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>{t('new.case.dialog.branch.tag')}</th>
            <td>
              {branch}
              &nbsp;/&nbsp;
              {tag}
            </td>
          </tr>
          <tr>
            <th>
              <label htmlFor="new-case-dialog-new-case-provider-selector" className="mandatory">
                {t('new.case.dialog.medical.provider')}
              </label>
            </th>
            <td>
              <Select
                id="new-case-dialog-new-case-provider-selector"
                className="new-case-dialog-new-case-provider-selector"
                placeholder={`${t('new.case.dialog.medical.provider.prompt')}`}
                options={medicalProviders}
                onChange={setProvider}
                value={provider}
              />
            </td>
          </tr>
          <tr>
            <th>
              <span className="mandatory">{t('new.case.dialog.evidence.requested')}</span>
            </th>
            <td>
              {renderProducts()}
            </td>
          </tr>
        </tbody>
      </table>
      <Spin spinning={loading} />
    </Modal>
  );
};

export default NewTestCaseDialog;
