import React, { FC, useEffect, useState } from 'react';
import {
  Button, Checkbox, Input, Modal, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';

import UserPermissions from '../../model/userPermissions';
import { getMetaDataGroups, getMetaDataProvider, getProfileServiceRoles } from '../../service/UserService';
import Roles from './Roles';

import User from '../../model/User';
import Role from '../../model/Role';

import './UserDialog.less';

//
// Edit the user's details.
// The supplied onExit function will be called with the (edited) details if yes is clicked and false if not.
//

const UserDialog: FC<{
  onExit: (response: User | boolean) => Promise<boolean>,
  open: boolean,
  title: string,
  userDetails: User,
  permissions: UserPermissions,
  profile: any,
  accessToken: string,
  create?: boolean
}> = ({
  onExit, title, open, userDetails, permissions, profile, accessToken, create = false,
}) => {
  const [user, setUser] = useState<User>(userDetails);
  const [initialUser, setInitialUser] = useState<User>(userDetails);
  const [busy, setBusy] = useState(false);
  const [provider, setProvider] = useState('');
  const [groups, setGroups] = useState('');
  const [invalidData, setInvalidData] = useState(true);
  const [serverRoles, setServerRoles] = useState<Role[]>([]);

  const { t } = useTranslation();

  //
  // Create valid metadata from the user details
  //
  const createMetadata = (providerId: string, providers: string) => {
    if (!providerId || providerId.trim().length === 0) return null;

    const providerArray = providers.split(/, */)
      .filter((p) => p && p.trim().length > 0)
      .filter((p) => p !== providerId);
    providerArray.unshift(providerId);
    return JSON.stringify({
      providerId,
      groups: [
        {
          name: providerId,
          providers: providerArray,
        },
      ],
    });
  };

  //
  // Validate the user details
  //
  const validateUser = (updatedUser: User) => {
    if (!updatedUser.forename || updatedUser.forename.trim().length === 0) {
      setInvalidData(true);
    } else if (!updatedUser.surname || updatedUser.surname.trim().length === 0) {
      setInvalidData(true);
    } else if (!updatedUser.email || updatedUser.email.trim().length === 0 || !updatedUser.email.match(/.+@[^.]+\..+/)) {
      setInvalidData(true);
    } else if (!updatedUser.username || updatedUser.username.trim().length === 0) {
      setInvalidData(true);
    } else {
      setInvalidData(false);
    }
  };

  //
  // Handle the dialog buttons
  //

  const doReset = (resetUser: User) => {
    let newProvider = '';
    let newGroups = '';
    if (create && permissions.canSystemAdmin) {
      newProvider = '';
      newGroups = '';
    } else if (permissions.canSystemAdmin) {
      newProvider = getMetaDataProvider(userDetails.metadata);
      // Don't display the provider as one of the groups
      newGroups = getMetaDataGroups(userDetails.metadata).filter((group: string) => group !== newProvider).join(', ');
    } else {
      newProvider = profile.providerId;
      // Don't display the provider as one of the groups
      newGroups = profile.groups[0].providers.filter((group: string) => group !== newProvider).join(', ');
    }
    setProvider(newProvider);
    setGroups(newGroups);

    const newDetails = { ...resetUser, metadata: createMetadata(newProvider, newGroups) };
    setUser(newDetails);
    validateUser(newDetails);
  };

  const handleReset = () => doReset(initialUser);

  const handleNo = () => {
    handleReset();
    onExit(false);
  };

  const handleYes = () => {
    setBusy(true);
    onExit(user)
      .then((response) => {
        if (response) handleReset();
        setBusy(false);
      });
  };

  //
  // Setup dialog initial state
  //

  const fetchRoles = (token: string) => {
    getProfileServiceRoles(token, t)
      .then((roles) => setServerRoles(roles.data));
  };

  useEffect(() => {
    setBusy(true);
    setInitialUser(userDetails);
    doReset(userDetails);
    fetchRoles(accessToken);
    setBusy(false);
  }, [userDetails]);

  //
  // Handle change of name (system admin in create mode only)
  //

  const handleForenameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedUser = { ...user, forename: event.target.value };
    setUser(updatedUser);
    validateUser(updatedUser);
  };

  const handleSurnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedUser = { ...user, surname: event.target.value };
    setUser(updatedUser);
    validateUser(updatedUser);
  };

  //
  // Handle change of email address (create mode only)
  //

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedUser = { ...user, email: event.target.value, username: event.target.value };
    setUser(updatedUser);
    validateUser(updatedUser);
  };

  //
  // Handle change of provider (system admin only)
  //

  const handleProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setProvider(value);
    setUser({ ...user, metadata: createMetadata(value, groups) });
  };

  const handleGroupsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setGroups(value);
    setUser({ ...user, metadata: createMetadata(provider, value) });
  };

  //
  // Handle changing role(s)
  //

  const handleRoleChange = (roles: string[]): void => {
    setUser({ ...user, roleNames: roles });
  };

  //
  // Handle toggling the active and enabled flags
  //

  const toggleEnabled = () => {
    setUser({ ...user, enabled: !user.enabled });
  };

  const toggleActive = () => {
    setUser({ ...user, active: !user.active });
  };

  //
  // Layout the dialog
  //
  return (
    <Modal
      title={title}
      onCancel={handleNo}
      open={open}
      destroyOnClose
      footer={[
        <Button key="reset" title={`${t('user.dialog.dialog.button.reset.title')}`} onClick={handleReset} disabled={busy}>
          {t('user.dialog.dialog.button.reset')}
        </Button>,
        <Button key="no" title={`${t('user.dialog.dialog.button.no.title')}`} onClick={handleNo} disabled={busy}>
          {t('user.dialog.dialog.button.no')}
        </Button>,
        <Button key="yes" type="primary" title={`${t('user.dialog.dialog.button.yes.title')}`} onClick={handleYes} disabled={busy || invalidData}>
          {t('user.dialog.dialog.button.yes')}
        </Button>,
      ]}
    >
      <table className="user-dialog-evidence-table">
        <tbody>
          <tr>
            <th><label htmlFor="user-dialog-forname" className="mandatory">{t('user.dialog.forename')}</label></th>
            <td>
              <Input
                id="user-dialog-forname"
                placeholder={`${t('user.dialog.forename.hint')}`}
                value={`${user.forename}`}
                onChange={handleForenameChange}
              />
            </td>
          </tr>
          <tr>
            <th><label htmlFor="user-dialog-surname" className="mandatory">{t('user.dialog.surname')}</label></th>
            <td>
              <Input
                id="user-dialog-surname"
                placeholder={`${t('user.dialog.surname.hint')}`}
                value={`${user.surname}`}
                onChange={handleSurnameChange}
              />
            </td>
          </tr>
          <tr>
            <th><label htmlFor="user-dialog-email" className="mandatory">{t('user.dialog.email')}</label></th>
            <td>
              <Input
                id="user-dialog-email"
                type="email"
                placeholder={`${t('user.dialog.email.hint')}`}
                value={`${user.email}`}
                onChange={handleEmailChange}
                disabled={!create}
              />
            </td>
          </tr>
          {
            permissions.canSystemAdmin && (
              <tr>
                <th><label htmlFor="user-dialog-username" className="mandatory">{t('user.dialog.username')}</label></th>
                <td>
                  <Input
                    id="user-dialog-username"
                    placeholder={`${t('user.dialog.username.hint')}`}
                    value={`${user.username}`}
                    disabled
                  />
                </td>
              </tr>
            )
          }
          <tr>
            <th><label htmlFor="user-dialog-roles">{t('user.dialog.roles')}</label></th>
            <td><Roles id="user-dialog-roles" serverRoles={serverRoles} userRoles={user.roleNames} onChange={handleRoleChange} /></td>
          </tr>
          <tr>
            <th><label htmlFor="user-dialog-provider">{t('user.dialog.provider')}</label></th>
            <td>
              <Input
                id="user-dialog-provider"
                placeholder={`${t('user.dialog.provider.hint')}`}
                value={`${provider}`}
                onChange={handleProviderChange}
                disabled={!permissions.canSystemAdmin}
              />
            </td>
          </tr>
          <tr>
            <th><label htmlFor="user-dialog-groups">{t('user.dialog.provider.groups')}</label></th>
            <td>
              <Input
                id="user-dialog-groups"
                placeholder={`${t('user.dialog.provider.groups.hint')}`}
                value={groups}
                onChange={handleGroupsChange}
                disabled={!permissions.canSystemAdmin}
              />
            </td>
          </tr>
          {
            create || (
              <tr>
                <th><label htmlFor="user-dialog-enabled">{t('user.dialog.enabled')}</label></th>
                <td>
                  <Checkbox id="user-dialog-enabled" checked={user.enabled} onClick={toggleEnabled} />
                </td>
              </tr>
            )
          }
          {
            create || (
              <tr>
                <th><label htmlFor="user-dialog-active">{t('user.dialog.active')}</label></th>
                <td>
                  <Checkbox id="user-dialog-active" checked={user.active} onClick={toggleActive} />
                </td>
              </tr>
            )
          }
        </tbody>
      </table>

      <div className="spinner">
        <Spin spinning={busy} />
      </div>
    </Modal>
  );
};

export default UserDialog;
