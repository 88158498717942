import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { TFunction } from 'i18next';

import {
  createUser, deleteUser, getRoles, getUsers, updateUser,
} from './dao/ManagementDao';

import Role from '../model/Role';
import Page from '../model/Page';
import User from '../model/User';

export interface UserParameters {
  page: number,
  pageSize: number,
  sort: string
}

/**
 * Get a page of profile service roles appropriate for the calling user.
 * @param token JWT token containing the user's permissions.
 * @param t Translation function from i18n.
 */
export const getProfileServiceRoles = (token: string, t: TFunction): Promise<AxiosResponse<Role []>> => getRoles(token)
  .catch((error) => {
    message.error(`${t('user.service.get.roles.error')} ${error.response.status}: ${error.response.statusText}`);
    return {
      ...error,
      data: [],
    };
  });

/**
 * Get a page of profile service users.
 * @param token JWT token containing the user's permissions.
 * @param enquiry Page selection supplied by the user table.
 * @param t Translation function from i18n.
 */
export const getProfileServiceUsers = (token: string, enquiry: UserParameters, t: TFunction): Promise<AxiosResponse<Page<User>>> => {
  const offset = (enquiry.page - 1) * enquiry.pageSize;
  return getUsers(token, enquiry.pageSize, offset, enquiry.sort)
    .catch((error) => {
      message.error(`${t('user.service.get.users.error')} ${error.response.status}: ${error.response.statusText}`);
      return { ...error, data: { data: [], count: 0, limit: 0 } };
    });
};

/**
 * Create a new user in the profile service.
 * @param token JWT token containing the user's permissions.
 * @param user The new user's details.
 * @param t Translation function from i18n.
 */
export const createProfileServiceUser = (token: string, user: User, t: TFunction): Promise<boolean> => createUser(token, user)
  .then((newUser) => newUser != null)
  .catch((error) => {
    message.error(`${t('user.service.create.user.error')} ${error.response.status}: ${error.response.statusText}`);
    return false;
  });

/**
 * Update an existing user in the profile service.
 * @param token JWT token containing the user's permissions.
 * @param user The user's new details.
 * @param t Translation function from i18n.
 */
export const updateProfileServiceUser = (token: string, user: User, t: TFunction): Promise<boolean> => updateUser(token, user)
  .then((newUser) => newUser != null)
  .catch((error) => {
    message.error(`${t('user.service.update.user.error')} ${error.response.status}: ${error.response.statusText}`);
    return false;
  });

/**
 * Extract a provider ID from decision studio metadata.
 * @param metadata
 */
export const getMetaDataProvider = (metadata: string | null): string => {
  if (metadata && metadata.trim().length > 0) {
    const profile = JSON.parse(metadata);
    if (profile.providerId) return profile.providerId;
  }
  return '';
};

/**
 * Extract a provider group from decision studio metadata.
 * @param metadata
 */
export const getMetaDataGroups = (metadata: string | null): string[] => {
  if (metadata && metadata.trim().length > 0) {
    const profile = JSON.parse(metadata);
    if (profile.groups && profile.groups.length > 0) {
      const group = profile.groups[0].providers;
      if (Array.isArray(group)) return group;
      return [];
    }
  }
  return [];
};

/**
 * Delete an existing user from the profile service.
 * @param token JWT token containing the user's permissions.
 * @param username The username of the user to delete.
 * @param t Translation function from i18n.
 */
export const deleteProfileServiceUser = (token: string, username: string, t: TFunction): Promise<boolean> => deleteUser(token, username)
  .then(() => true)
  .catch((error) => {
    message.error(`${t('user.service.delete.user.error')} ${error.response.status}: ${error.response.statusText}`);
    return false;
  });
