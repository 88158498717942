import React, { FC, useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import {
  Button, Switch, Table, TablePaginationConfig,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { BaseOptionType } from 'rc-select/lib/Select';

import { UserParameters } from '../service/UserService';

import UserPermissions from '../model/userPermissions';
import ModelConfig from '../model/ModelConfig';
import ModelDialog from './ModelDialog/ModelDialog';
import YesNoDialog from '../component/YesNoDialog';

import './ModelTable.less';

/**
 * Display a paginated list of model configurations.
 */
const ModelTable: FC<{ permissions: UserPermissions }> = ({ permissions }) => {
  const nullConfig: ModelConfig = {
    index: 0,
    provider: '',
    name: '',
    url: '',
    adapter: '',
    enabled: false,
  };

  const { t } = useTranslation();
  const [params, setParams] = useState<UserParameters>({ pageSize: 9, page: 1, sort: 'ascending' });
  const [configs, setConfigs] = useState<ModelConfig[]>();
  const [total, setTotal] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [currentConfig, setCurrentConfig] = useState(nullConfig);

  const error = false;
  // Hard code dome model config for demo
  const demoConfigs: ModelConfig[] = [
    {
      index: 0,
      provider: 'HSBC',
      name: 'PROP',
      adapter: 'Rules Engine Format',
      url: 'http://ds-external-services-stub-ds-dev-01/models/ume/v1/predict',
      enabled: true,
    },
    {
      index: 1,
      provider: 'HSBC',
      name: 'DEMO',
      adapter: 'Rules Engine Format',
      url: 'http://eb-3-dev.eu-west-1.elasticbeanstalk.com/predict',
      enabled: false,
    },
    {
      index: 2,
      provider: 'HSBC',
      name: 'DUMMY',
      adapter: 'Rules Engine Format',
      url: 'http://eb-3-dev.eu-west-1.elasticbeanstalk.com/predict',
      enabled: true,
    },
  ];

  const providers: BaseOptionType[] = [
    { label: 'HSBC', value: 'HSBC' },
    { label: 'HSBC_PP', value: 'HSBC_PP' },
    { label: 'HSBC_EXT', value: 'HSBC_EXT' },
  ];

  const adapters: BaseOptionType[] = [
    { label: 'Rules Engine Format', value: 'ume.propensity_to_buy' },
  ];

  const fetchConfigs = () => {
    setIsLoading(true);
    setConfigs(demoConfigs);
    setTotal(demoConfigs.length);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchConfigs();
  }, [params]);

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ModelConfig> | SorterResult<ModelConfig>[],
  ) => {
    const changeParams: UserParameters = {
      pageSize: pagination.pageSize || 10,
      page: pagination.current || 1,
      sort: 'ascending',
    };
    if (!Array.isArray(sorter)) {
      changeParams.sort = sorter.order as string;
    }
    setParams(changeParams);
  };

  const openEditDialog = (config: ModelConfig) => {
    setCurrentConfig(config);
    setDialogOpen(true);
  };

  const openAddDialog = () => {
    openEditDialog(nullConfig);
  };

  const updateModelConfig = (config: ModelConfig | boolean): Promise<boolean> => {
    if (config) {
      // TODO update here
    }
    setDialogOpen(false);
    return Promise.resolve(true);
  };

  const renderEditButton = (config: ModelConfig): JSX.Element | string => {
    if (permissions.canUpdateUser /* TODO use model permissions */) {
      return (
        <Button key="update" title={`${t('model.table.update.title')}`} onClick={() => openEditDialog(config)}>
          {t('model.table.update')}
        </Button>
      );
    }
    return '';
  };

  const renderDeleteButton = (): JSX.Element | string => {
    if (permissions.canDeleteUser /* TODO use model permissions */) {
      return (
        <Button key="delete" title={`${t('model.table.delete.title')}`}>
          {t('model.table.delete')}
        </Button>
      );
    }
    return '';
  };

  const openConfigDialog = (config: ModelConfig) => {
    setCurrentConfig(config);
    setConfirmDialogOpen(true);
  };

  const toggleConfig = (action: boolean): void => {
    if (action) {
      demoConfigs[currentConfig.index].enabled = !currentConfig.enabled;
      setConfigs(demoConfigs);
    }
    setConfirmDialogOpen(false);
  };

  const renderEnableButton = (config: ModelConfig): JSX.Element | string => {
    if (permissions.canSystemAdmin /* TODO use model permissions */) {
      return (<Switch checked={config.enabled} onClick={() => openConfigDialog(config)} />);
    }
    return config.enabled ? `${t('model.table.enabled')}` : `${t('model.table.disabled')}`;
  };

  const columns = [
    {
      title: t('model.table.provider'),
      dataIndex: 'provider',
      hidden: !permissions.canSystemAdmin, // TODO user model permissions
    },
    {
      title: t('model.table.name'),
      dataIndex: 'name',
    },
    {
      title: t('model.table.url'),
      dataIndex: 'url',
    },
    {
      title: t('model.table.adapter'),
      dataIndex: 'adapter',
    },
    {
      title: t('model.table.state'),
      render: (config: ModelConfig) => renderEnableButton(config),
    },
    {
      title: '',
      render: (config: ModelConfig) => renderEditButton(config),
    },
    {
      title: '',
      render: () => renderDeleteButton(),
    },
  ]
    .filter((column) => !column.hidden); // Don't display the provider unless a system admin

  if (error || (!permissions.canSystemAdmin && !permissions.canOrgAdmin)) {
    return (
      <div>Access Denied</div>
    );
  }

  return (
    <div className="model-table">
      {
        permissions.canCreateUser /* TODO use model permissions */ && (
          <>
            <Button className="new-model-button" type="primary" title={`${t('model.table.create.title')}`} onClick={openAddDialog}>
              {t('model.table.create')}
            </Button>

            <ModelDialog
              onExit={updateModelConfig}
              open={dialogOpen}
              config={currentConfig}
              permissions={permissions}
              providers={providers}
              adapters={adapters}
            />
          </>
        )
      }

      <Table
        className="enquiry-table"
        scroll={{ x: true }}
        columns={columns}
        dataSource={configs}
        rowKey="index"
        loading={isLoading}
        pagination={{
          pageSize: params.pageSize,
          current: params.page,
          total,
          hideOnSinglePage: true,
        }}
        onChange={handleChange}
      />

      <YesNoDialog
        title={t(currentConfig.enabled ? 'model.confirm.dialog.title.disable' : 'model.confirm.dialog.title.enable')}
        onExit={toggleConfig}
        open={confirmDialogOpen}
      >
        {t(currentConfig.enabled ? 'model.confirm.dialog.disable' : 'model.confirm.dialog.enable')}
        <br />
        <br />
        <strong>{t('model.confirm.dialog.model')}</strong>
        &nbsp;
        &nbsp;
        {currentConfig.name}
      </YesNoDialog>
    </div>
  );
};

export default withAuthenticationRequired(ModelTable, {});
