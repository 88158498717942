import { message } from 'antd';
import { TFunction } from 'i18next';

import { getFile } from './dao/ManagementDao';

/**
 * Get a file from a backend service that requires user authentication.
 * @param token JWT token containing the user's permissions.
 * @param enquiryId ID of the enquiry that own the file (document).
 * @param fileName Name of the file to retrieve.
 * @param t Translation function from i18n.
 */
export const getAttachedFile = async (
  token: string,
  enquiryId: string,
  fileName: string,
  t: TFunction,
): Promise<string | null> => getFile(token, enquiryId, fileName)
  .then((response) => {
    const contentType = response.headers['content-type'];
    const binaryData: BlobPart[] = [response.data];
    return window.URL.createObjectURL(new Blob(binaryData, { type: contentType }));
  })
  .catch((error) => {
    message.error(`${t('file.service.get.file.error')}: ${error}`);
    return null;
  });
