import axios, { AxiosResponse } from 'axios';

import { getProviderId } from '../permissionService';

import { EnquiryResponse } from '../../model/enquiryResponse';
import { EnquiryOverrides } from '../../model/EnquiryOverrides';
import { MedicalProviderOptions } from '../../model/medicalProviderOptions';

import answers from '../../TestingTab/data/enquiryAnswers.json';

export const startEnquiry = (
  token: string,
  user: string,
  enquiryOverrides: EnquiryOverrides,
  branch: string,
  collation: string,
  tag: string,
): Promise<AxiosResponse<EnquiryResponse>> => {
  const providerId: string = getProviderId(token);

  answers.OVERRIDE_PROVIDER = enquiryOverrides.providers;
  answers.OVERRIDE_EVIDENCE = enquiryOverrides.products;

  return axios.post<EnquiryResponse>(
    '/re/v4/startEnquiry',
    {
      username: user,
      answers,
    },
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
      params: {
        branch,
        collation,
        tag,
      },
    },
  );
};

export const closeEnquiry = (token: string, user: string, enquiryId: string): Promise<AxiosResponse<EnquiryResponse>> => {
  const providerId: string = getProviderId(token);

  return axios.post<EnquiryResponse>(
    `/re/v4/closeEnquiry/${enquiryId}`,
    {
      username: user,
    },
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getMedicalProviderOptions = (token: string, branch: string, tag:string): Promise<AxiosResponse<MedicalProviderOptions>> => {
  const providerId: string = getProviderId(token);

  return axios.get<MedicalProviderOptions>(
    `/re/v4/optionList/${branch}/${tag}/Med_ProviderOptions`,
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
