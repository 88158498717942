import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import StatusTable from './StatusTable';

const StatusTab: FC<{ accessToken: string }> = ({ accessToken }) => {
  const { t } = useTranslation();

  return (
    <div className="status-tab">
      <h1 className="tab-heading">{t('status.tab.title')}</h1>
      <StatusTable accessToken={accessToken} />
    </div>
  );
};

export default StatusTab;
