import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ReviewTable from './ReviewTable';

const ReviewTab: FC<{ accessToken: string }> = ({ accessToken}) => {
  const { t } = useTranslation();

  return (
    <div className="review-tab">
      <h1 className="tab-heading">{t('review.tab.title')}</h1>
      <ReviewTable accessToken={accessToken} />
    </div>
  );
};

export default ReviewTab;
