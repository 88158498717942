import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ProviderConfigManagement: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="provider-config">
      <h2 className="tab-heading">{t('admin.task.provider')}</h2>
      <div>Not implemented yet.</div>
    </div>
  );
};

export default ProviderConfigManagement;
