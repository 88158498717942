import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import UserTable from './UserTable';

import UserPermissions from '../model/userPermissions';

const UserManagement: FC<{ permissions: UserPermissions, accessToken: string }> = ({ permissions, accessToken }) => {
  const { t } = useTranslation();

  return (
    <div className="user-management">
      <h2 className="tab-heading">{t('admin.task.user')}</h2>
      <UserTable permissions={permissions} accessToken={accessToken} />
    </div>
  );
};

export default UserManagement;
