import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ManualTable from './ManualTable';

const ManualTab: FC<{ accessToken: string }> = ({ accessToken}) => {
  const { t } = useTranslation();

  return (
    <div className="manual-tab">
      <h1 className="tab-heading">{t('manual.tab.title')}</h1>
      <ManualTable accessToken={accessToken} />
    </div>
  );
};

export default ManualTab;
