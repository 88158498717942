import React, { FC, useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import {
  Button, message, Table, TablePaginationConfig,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { AlignType } from 'rc-table/lib/interface';

import {
  createProfileServiceUser, deleteProfileServiceUser, getMetaDataProvider, getProfileServiceUsers, updateProfileServiceUser, UserParameters,
} from '../service/UserService';
import UserDialog from './UserDialog/UserDialog';
import { getLogin, getProfile } from '../service/permissionService';
import YesNoDialog from '../component/YesNoDialog';

import User from '../model/User';
import UserPermissions from '../model/userPermissions';

import './UserTable.less';

/**
 * Display a paginated list of profile service users.
 */
const UserTable: FC<{ permissions: UserPermissions, accessToken: string }> = ({ permissions, accessToken }) => {
  const nullUser: User = {
    username: '',
    email: '',
    forename: '',
    surname: '',
    metadata: null,
    roleNames: [],
    enabled: true,
    active: true,
  };

  const [isLoading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<User[]>([]);
  const [params, setParams] = useState<UserParameters>({ pageSize: 9, page: 1, sort: 'ascending' });
  const [total, setTotal] = useState<number>();
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>(nullUser);
  const [profile, setProfile] = useState<any>({
    providerId: '',
    groups: [
      {
        name: '',
        providers: [],
      },
    ],
  });
  const [login, setLogin] = useState('');

  const { t } = useTranslation();

  const fetchUsers = (token: string, userparams: UserParameters) => {
    if (token) {
      setLoading(true);
      getProfileServiceUsers(token, userparams, t)
        .then((data) => {
          setUsers(data.data.data);
          setTotal((data.data.count));
        })
        .finally(() => setLoading(false));
    } else {
      message.error('Unable to retrieve the access token');
    }
  };

  useEffect(() => {
    fetchUsers(accessToken, params);
    setProfile(getProfile(accessToken));
    setLogin(getLogin(accessToken));
  }, [params]);

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    const changeParams: UserParameters = {
      pageSize: pagination.pageSize || 10,
      page: pagination.current || 1,
      sort: 'ascending',
    };
    if (!Array.isArray(sorter)) {
      changeParams.sort = sorter.order as string;
    }
    setParams(changeParams);
  };

  const openUserNewDialog = () => {
    setSelectedUser(nullUser);
    setCreateUserDialogOpen(true);
  };

  const createNewUser = (user: User | boolean): Promise<boolean> => {
    if (typeof user !== 'boolean') {
      return createProfileServiceUser(accessToken, user, t)
        .then((response) => {
          if (response) setCreateUserDialogOpen(false);
          return Promise.resolve(response);
        });
    } else {
      setCreateUserDialogOpen(false);
      return Promise.resolve(false);
    }
  };

  const openEditUserDialog = (user: User) => {
    setSelectedUser(user);
    setEditUserDialogOpen(true);
  };

  const updateExistingUser = (user: User | boolean): Promise<boolean> => {
    if (typeof user !== 'boolean') {
      return updateProfileServiceUser(accessToken, user, t)
        .then((response) => {
          if (response) {
            fetchUsers(accessToken, params);
            setEditUserDialogOpen(false);
          }
          return Promise.resolve(response);
        });
    } else {
      setEditUserDialogOpen(false);
      return Promise.resolve(false);
    }
  };

  const openDeleteUserDialog = (user: User) => {
    setSelectedUser(user);
    setDeleteUserDialogOpen(true);
  };

  const deleteUser = (confirmed: boolean): Promise<boolean> => {
    if (confirmed) {
      return deleteProfileServiceUser(accessToken, selectedUser.username, t)
        .then((response) => {
          if (response) {
            fetchUsers(accessToken, params);
            setDeleteUserDialogOpen(false);
          }
          return Promise.resolve(response);
        });
    } else {
      setDeleteUserDialogOpen(false);
      return Promise.resolve(false);
    }
  };

  const renderBoolean = (value: boolean): string => (value ? t('user.table.value.true') : t('user.table.value.false'));

  const renderEditButton = (user: User): JSX.Element | string => {
    if (permissions.canUpdateUser) {
      return (
        <Button key="edit" title={`${t('user.table.edit.title')}`} onClick={() => openEditUserDialog(user)}>
          {t('user.table.edit')}
        </Button>
      );
    }
    return '';
  };

  const renderDeleteButton = (user: User): JSX.Element | string => {
    if (permissions.canDeleteUser && user.username !== login) {
      return (
        <Button key="delete" title={`${t('user.table.delete.title')}`} onClick={() => openDeleteUserDialog(user)}>
          {t('user.table.delete')}
        </Button>
      );
    }
    return '';
  };

  const columns = [
    {
      title: t('user.table.name'),
      render: (user: User) => `${user?.forename} ${user.surname}`,
    },
    {
      title: t('user.table.email'),
      dataIndex: 'email',
    },
    {
      title: t('user.table.provider'),
      render: (user: User) => getMetaDataProvider(user.metadata),
      hidden: !permissions.canSystemAdmin,
    },
    {
      title: t('user.table.roles'),
      render: (user: User) => user?.roleNames.join(', '),
    },
    {
      title: t('user.table.enabled'),
      render: (user: User) => renderBoolean(user.enabled),
      align: 'center' as AlignType,
    },
    {
      title: t('user.table.active'),
      render: (user: User) => renderBoolean(user.active),
      align: 'center' as AlignType,
    },
    {
      title: '',
      render: (user: User) => (renderEditButton(user)),
    },
    {
      title: '',
      render: (user: User) => (renderDeleteButton(user)),
    },
  ]
    .filter((column) => !column.hidden); // Don't display the provider unless a system admin

  if (!permissions.canSystemAdmin && !permissions.canOrgAdmin) {
    return (
      <div>Access Denied</div>
    );
  }

  return (
    <div className="user-table">
      {
        permissions.canCreateUser && (
          <>
            <Button className="new-user-button" type="primary" title={`${t('user.table.create.title')}`} onClick={openUserNewDialog}>
              {t('user.table.create')}
            </Button>

            <UserDialog
              open={createUserDialogOpen}
              title={t('user.dialog.create.title')}
              userDetails={selectedUser}
              permissions={permissions}
              profile={profile}
              accessToken={accessToken}
              create
              onExit={createNewUser}
            />
          </>
        )
      }

      <Table
        className="enquiry-table"
        scroll={{ x: true }}
        columns={columns}
        dataSource={users}
        rowKey="username"
        loading={isLoading}
        pagination={{
          pageSize: params.pageSize,
          current: params.page,
          total,
        }}
        onChange={handleChange}
      />

      {
        permissions.canUpdateUser && (
          <UserDialog
            open={editUserDialogOpen}
            title={t('user.dialog.edit.title')}
            userDetails={selectedUser}
            permissions={permissions}
            profile={profile}
            accessToken={accessToken}
            onExit={updateExistingUser}
          />
        )
      }

      {
        permissions.canDeleteUser && (
          <YesNoDialog title={`${t('delete.user.dialog.title')} ${selectedUser.username}`} onExit={deleteUser} open={deleteUserDialogOpen}>
            {`${t('delete.user.dialog.prefix')} ${selectedUser.forename} ${selectedUser.surname}${t('delete.user.dialog.suffix')}`}
            <p />
            <b>{t('delete.user.dialog.warning')}</b>
          </YesNoDialog>
        )
      }
    </div>
  );
};

export default withAuthenticationRequired(UserTable, {});
