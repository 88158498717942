import React, { FC, useEffect, useState } from 'react';
import {
  Button, Input, Modal, Select, Switch,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseOptionType } from 'rc-select/lib/Select';

import UserPermissions from '../../model/userPermissions';
import ModelConfig from '../../model/ModelConfig';

import './ModelDialog.less';

const ModelDialog: FC<{
  onExit: (response: ModelConfig | boolean) => Promise<boolean>,
  open: boolean,
  config: ModelConfig,
  permissions: UserPermissions,
  providers: BaseOptionType[],
  adapters: BaseOptionType[],
}> = ({
  onExit, open, config, permissions, providers, adapters,
}) => {
  const [currentConfig, setCurrentConfig] = useState(config);
  const [busy, setBusy] = useState(false);
  const [invalidData, setInvalidData] = useState(false);

  const { t } = useTranslation();

  const handleReset = () => {
    setCurrentConfig(config);
  };

  const handleNo = () => {
    onExit(false);
  };

  const handleYes = () => {
    setBusy(true);
    onExit(currentConfig)
      .then((response) => {
        if (response) handleReset();
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const setEnabled = (enabled: boolean): void => {
    setCurrentConfig({ ...currentConfig, enabled });
  };

  useEffect(() => {
    setBusy(true);
    setCurrentConfig(config);
    setBusy(false);
  }, [config]);

  return (
    <Modal
      title={t('model.dialog.title')}
      onCancel={handleNo}
      open={open}
      destroyOnClose
      footer={[
        <Button key="reset" title={`${t('model.dialog.dialog.button.reset.title')}`} onClick={handleReset} disabled={busy}>
          {t('model.dialog.dialog.button.reset')}
        </Button>,
        <Button key="no" title={`${t('model.dialog.dialog.button.cancel.title')}`} onClick={handleNo} disabled={busy}>
          {t('model.dialog.dialog.button.cancel')}
        </Button>,
        <Button key="yes" type="primary" title={`${t('model.dialog.dialog.button.save.title')}`} onClick={handleYes} disabled={busy || invalidData}>
          {t('model.dialog.dialog.button.save')}
        </Button>,
      ]}
    >
      <table className="model-dialog-table">
        <tbody>
          { permissions.canSystemAdmin && (
          <tr>
            <th className="mandatory">{t('model.dialog.provider')}</th>
            <td>
              <Select
                options={providers}
                defaultValue={currentConfig.provider === '' ? null : currentConfig.provider}
                placeholder={t('model.dialog.provider.hint')}
              />
            </td>
          </tr>
          )}
          <tr>
            <th className="mandatory">{t('model.dialog.name')}</th>
            <td><Input value={currentConfig.name} placeholder={`${t('model.dialog.name.hint')}`} /></td>
          </tr>
          <tr>
            <th className="mandatory">{t('model.dialog.url')}</th>
            <td><Input value={currentConfig.url} placeholder={`${t('model.dialog.url.hint')}`} /></td>
          </tr>
          <tr>
            <th className="mandatory">{t('model.dialog.adapter')}</th>
            <td>
              <Select
                options={adapters}
                defaultValue={currentConfig.adapter === '' ? null : currentConfig.adapter}
                placeholder={t('model.dialog.adapter.hint')}
              />
            </td>
          </tr>
          { permissions.canSystemAdmin && (
          <tr>
            <th>{t('model.dialog.enabled')}</th>
            <td><Switch checked={currentConfig.enabled} onChange={setEnabled} /></td>
          </tr>
          )}
        </tbody>
      </table>
      <span className="mandatory" />
      <span>
        &nbsp;
        {t('model.dialog.mandatory')}
      </span>
    </Modal>
  );
};

export default ModelDialog;
