import axios, { AxiosResponse } from 'axios';
import { getProviderId } from '../permissionService';

import Role from '../../model/Role';
import Page from '../../model/Page';
import User from '../../model/User';

export const getRoles = (token: string):Promise<AxiosResponse<Role []>> => {
  const providerId: string = getProviderId(token);

  return axios.get<Role []>(
    '/ms/v1/roles',
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

/**
 * Get a page of profile service users.
 * @param token JWT token containing the user's permissions.
 * @param limit The number of users to return in the page.
 * @param offset The starting index of the first user on the page.
 * @param sort The sort direction (ascending/descending).
 */
export const getUsers = (token: string, limit: number, offset: number, sort: string): Promise<AxiosResponse<Page<User>>> => {
  const providerId: string = getProviderId(token);

  return axios.get<Page<User>>(
    `/ms/v1/users?limit=${limit}&offset=${offset}&sortDirection=${sort}`,
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

/**
 * Create a new profile service user.
 * @param token JWT token containing the user's permissions.
 * @param user The details of the new user.
 */
export const createUser = (token: string, user: User): Promise<AxiosResponse<User>> => {
  const providerId: string = getProviderId(token);

  return axios.post(
    '/ms/v1/users',
    user,
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

/**
 * Update an existing profile service user.
 * @param token JWT token containing the user's permissions.
 * @param user The new details of the user.
 */
export const updateUser = (token: string, user: User): Promise<AxiosResponse<User>> => {
  const providerId: string = getProviderId(token);

  return axios.put(
    '/ms/v1/users',
    user,
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

/**
 * Delete an existing profile service user.
 * @param token JWT token containing the user's permissions.
 * @param username The username of the user to delete.
 */
export const deleteUser = (token: string, username: string): Promise<AxiosResponse<void>> => {
  const providerId: string = getProviderId(token);

  return axios.delete(
    `/ms/v1/users/${username}`,
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

/**
 * Retrieve a file from the management service.
 * @param token JWT token containing the user's permissions.
 * @param enquiryId ID of the enquiry to which the file is attached.
 * @param fileName Name of the file to retrieve.
 */
export const getFile = (token: string, enquiryId: string, fileName: string): Promise<AxiosResponse<any>> => {
  const providerId: string = getProviderId(token);

  return axios.get(
    `/ms/v1/file/${enquiryId}/${fileName}`,
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
      responseType: 'arraybuffer',
    },
  );
};
