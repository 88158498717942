import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import OkDialog from '../OkDialog';

import './EnvironmentName.less';

/**
 * Display the environment's name and provide a mouse-over and pop-up that shows the version number.
 * @param name - The name of the environment
 * @param version - The environment's version number
 * @param domain - The Auth0 domain used for authentication/authorisation.
 */
const EnvName: FC<{ name: string, version: string, domain: string }> = ({ name, version, domain }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  return (
    <>
      <span className="env-name" title={version} onClick={openDialog}>{name}</span>
      <OkDialog title="Environment" onExit={closeDialog} open={dialogOpen}>
        <table className="env-name-dialog">
          <tbody>
            <tr>
              <th>{t('environment.name.environment')}</th>
              <td>{name}</td>
            </tr>
            <tr>
              <th>{t('environment.name.version')}</th>
              <td>{version}</td>
            </tr>
            <tr>
              <th>{t('environment.name.domain')}</th>
              <td>{domain}</td>
            </tr>
          </tbody>
        </table>
      </OkDialog>
    </>
  );
};

export default EnvName;
