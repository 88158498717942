import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

import Role from '../../model/Role';

//
// Select option description
//
interface Option {
  label: string;
  value: string;
}

/**
 * Display the user's roles and allow deselection and selection from a list of other roles.
 * @param id Unique identifier for the component (optional).
 * @param serverRoles The roles available for selection.
 * @param userRoles The user's current roles.
 * @param onChange Function to update the user's roles with the newly chosen selection.
 */
const Roles: FC<{
  id?: string,
  serverRoles: Role[],
  userRoles: string [],
  onChange: (roles: string[]) => void,
}> = ({
  id = '',
  serverRoles,
  userRoles,
  onChange,
}) => {
  const [roleNames, setRoleNames] = useState(userRoles);

  const { t } = useTranslation();

  useEffect(() => {
    setRoleNames(userRoles);
  }, [userRoles]);

  //
  // Handle role changes
  //

  const handleSelect = (type: Option, option: Option) => {
    if (!roleNames.includes(option.label)) {
      const newRoles = [...roleNames, option.label];
      setRoleNames(newRoles);
      onChange(newRoles);
    }
  };
  const handleDeselect = (type: Option, option: Option) => {
    if (roleNames.indexOf(option.label) > -1) {
      const newRoles = roleNames.filter((role) => role !== option.label);
      setRoleNames(newRoles);
      onChange(newRoles);
    }
  };

  const displayRoles: Option[] = serverRoles.map((role) => ({ label: role.name, value: role.id }));

  const getUserRoles = (): Option[] => serverRoles.filter((role) => roleNames.includes(role.name)).map((role) => ({
    label: role.name,
    value: role.id,
  }));

  return (
    <Select
      id={id}
      className="user-dialog-roles"
      mode="multiple"
      autoClearSearchValue
      options={displayRoles}
      value={getUserRoles()}
      placeholder={t('user.roles.hint')}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      showArrow
      showSearch={false}
    />
  );
};

export default Roles;
