import React, { FC, useEffect, useState } from 'react';
import { message, Button } from 'antd';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { Enquiry } from '../model/enquiry';
import { TestEvidence } from '../model/testEvidence';
import { EnquiryOverrides } from '../model/EnquiryOverrides';
import { DEFAULT_PERMISSIONS } from '../model/userPermissions';
import { EvidenceProvider } from '../model/EvidenceProvider';

import { getEnquiries } from '../service/dao/EnquiryDao';
import {
  compareApplicationId, compareName, compareUpdated, compareEvidenceRequested, compareEvidenceProvider, compareBookingStatus,
} from '../service/enquiryService';
import LinkButton from '../component/LinkButton';
import { bookingConfirmed, createTestEnquiry, sendEvidence } from '../service/TestingService';
import { getLogin, getPermissions } from '../service/permissionService';
import EnquiryTable, {
  renderBookingStatus,
  renderBookingStatusUpdated,
  renderEvidenceProvider,
  renderEvidenceRequested,
} from '../component/EnquiryTable/EnquiryTable';
import EditMedicalEvidenceDialog from './EditMedicalEvidenceDialog/EditMedicalEvidenceDialog';
import NewTestCaseDialog from './NewTestCaseDialog/NewTestCaseDialog';
import ConfirmBookingDialog from './ConfirmBookingDialog/ConfirmBookingDialog';

import { EnvironmentConfig } from '../model/EnvironmentConfig';

import './TestingTable.less';

const TestingTable: FC<{ env: EnvironmentConfig, accessToken: string }> = ({ env, accessToken }) => {
  const [user, setUser] = useState<string>('');
  const [permissions, setPermissions] = useState(DEFAULT_PERMISSIONS);
  const [newCaseDialogOpen, setNewCaseDialogOpen] = useState(false);
  const [confirmBookingDialogOpen, setConfirmBookingDialogOpen] = useState(false);
  const [sendEvidenceDialogOpen, setSendEvidenceDialogOpen] = useState(false);
  const [currentEnquiry, setCurrentEnquiry] = useState<null | Enquiry>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setUser(getLogin(accessToken));
    setPermissions(getPermissions(accessToken));
  }, []);

  // New case button/dialog handling

  const openNewCaseDialog = (): void => {
    setNewCaseDialogOpen(true);
  };

  const createNewEnquiry = (
    enquiryOverrides: EnquiryOverrides | boolean,
    forename: string,
    surname: string,
    branch: string,
    tag: string,
  ): void => {
    if (typeof enquiryOverrides !== 'boolean') {
      createTestEnquiry(accessToken, user, enquiryOverrides, forename, surname, branch, tag, env.enquiryCreationDelay)
        .then((data) => message.info(`${t('testing.message.enquiry.created')} ${data.data.applicationId}`))
        .catch(() => message.error(t('testing.message.enquiry.not.created')));
    }
    setNewCaseDialogOpen(false);
  };

  // Booking confirmation button/dialog handling

  const openConfirmBookingDialog = (enquiry: Enquiry): void => {
    setCurrentEnquiry(enquiry);
    setConfirmBookingDialogOpen(true);
  };

  const confirmBooking = (confirmed: boolean): void => {
    if (confirmed && currentEnquiry != null) {
      currentEnquiry.actioned = true;
      bookingConfirmed(accessToken, currentEnquiry.enquiryId)
        .then(() => message.info(t('testing.message.booking.confirmed')))
        .catch(() => {
          message.error(t('testing.message.booking.not.confirmed'));
          currentEnquiry.actioned = false;
        });
    }
    setConfirmBookingDialogOpen(false);
  };

  // Send medical evidence button.dialog handling

  const openSendEvidenceDialog = (enquiry: Enquiry): void => {
    setCurrentEnquiry(enquiry);
    setSendEvidenceDialogOpen(true);
  };

  const validateMetadata = (metadata: string): boolean => {
    try {
      JSON.parse(`${metadata}`);
      return true;
    } catch (exception: any) {
      message.error(exception.message);
      return false;
    }
  };

  const confirmEvidence = (testEvidence: TestEvidence | boolean): boolean => {
    if (typeof testEvidence !== 'boolean' && currentEnquiry != null) {
      if (!validateMetadata(testEvidence.metadata)) return false;
      currentEnquiry.actioned = true;
      sendEvidence(accessToken, currentEnquiry.enquiryId, testEvidence)
        .then(() => message.info(t('testing.message.evidence.sent')))
        .catch(() => {
          message.error(t('testing.message.evidence.not.sent'));
          currentEnquiry.actioned = false;
        });
    }
    setSendEvidenceDialogOpen(false);
    return true;
  };

  // Render the action buttons appropriate for the given enquiry
  const renderActions = (enquiry: Enquiry) => {
    if (permissions.canAction && !enquiry.actioned) {
      for (const provider of enquiry.evidenceProviders) {
        switch (provider.bookingStatus.toUpperCase()) {
          case 'PROCESSING':
            return (
              <Button title={`${t('testing.button.confirm.booking.title')}`} onClick={() => openConfirmBookingDialog(enquiry)}>
                {t('testing.button.confirm.booking')}
              </Button>
            );
          case 'CONFIRMED':
            return (
              <Button title={`${t('testing.button.send.evidence.title')}`} onClick={() => openSendEvidenceDialog(enquiry)}>
                {t('testing.button.send.evidence')}
              </Button>
            );
          default:
          // do nothing here, fall through
        }
      }
    }
    return '';
  };

  const columns = [
    {
      title: t('testing.table.appid'),
      dataIndex: 'applicationId',
      render: (id: string, enquiry: Enquiry) => (<LinkButton text={id} url={enquiry.applicationUrl} maxWidth={180} />),
      sorter: { compare: compareApplicationId },
    },
    {
      title: t('testing.table.name'),
      dataIndex: 'name',
      render: (_: any, enquiry: Enquiry) => `${enquiry.firstName} ${enquiry.surname}`,
      sorter: { compare: compareName },
    },
    {
      title: t('testing.table.last.updated'),
      dataIndex: 'evidenceProviders',
      render: (providers: EvidenceProvider[]) => (renderBookingStatusUpdated(providers)),
      sorter: { compare: compareUpdated },
    },
    {
      title: t('testing.table.evidence.requested'),
      dataIndex: 'evidenceProviders',
      render: (providers: EvidenceProvider[]) => (renderEvidenceRequested(providers)),
      sorter: { compare: compareEvidenceRequested },
    },
    {
      title: t('testing.table.evidence.provider'),
      dataIndex: 'evidenceProviders',
      render: (providers: EvidenceProvider[]) => (renderEvidenceProvider(providers)),
      sorter: { compare: compareEvidenceProvider },
    },
    {
      title: t('testing.table.status'),
      dataIndex: 'evidenceProviders',
      render: (providers: EvidenceProvider[]) => (renderBookingStatus(providers)),
      sorter: { compare: compareBookingStatus },
    },
    {
      title: t('testing.table.action'),
      dataIndex: 'parentEnquiryId',
      render: (_: any, enquiry: Enquiry) => (renderActions(enquiry)),
    },
  ];

  return (
    <div className="testing-table">
      {
        (permissions.canCreate && env.enableCreateButton) && (
          <>
            <Button className="new-case-button" type="primary" title={`${t('testing.button.new.enquiry.title')}`} onClick={openNewCaseDialog}>
              {t('testing.button.new.enquiry')}
            </Button>

            <NewTestCaseDialog
              onExit={createNewEnquiry}
              open={newCaseDialogOpen}
              branch={env.enquiryCreationBranch}
              tag={env.enquiryCreationTag}
            />
          </>
        )
      }

      <EnquiryTable columns={columns} getEnquiries={getEnquiries} accessToken={accessToken} />

      {
        permissions.canAction && (
          <>
            <ConfirmBookingDialog onExit={confirmBooking} open={confirmBookingDialogOpen} enquiry={currentEnquiry} />

            <EditMedicalEvidenceDialog
              name={`${currentEnquiry?.firstName} ${currentEnquiry?.surname}`}
              provider={currentEnquiry?.evidenceProviders.map((provider) => provider.evidenceProvider).join(', ')}
              onExit={confirmEvidence}
              open={sendEvidenceDialogOpen}
            />
          </>
        )
      }
    </div>
  );
};

export default withAuthenticationRequired(TestingTable, {
});
