import React, { FC, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

import { Enquiry } from '../model/enquiry';
import { EvidenceProvider } from '../model/EvidenceProvider';
import { getEnquiries } from '../service/dao/EnquiryDao';
import {
  compareApplicationId, compareName, compareUpdated, compareEvidenceRequested, compareEvidenceProvider, compareBookingStatus,
} from '../service/enquiryService';
import LinkButton from '../component/LinkButton';
import EnquiryTable, {
  enquiryLinks,
  renderBookingStatus,
  renderBookingStatusUpdated,
  renderEvidenceProvider,
  renderEvidenceRequested,
} from '../component/EnquiryTable/EnquiryTable';

import './StatusTable.less';

const StatusTable: FC<{ accessToken: string }> = ({ accessToken }) => {
  const [busy, setBusy] = useState<boolean>(false);
  const { t } = useTranslation();

  const columns = [
    {
      title: t('status.table.appid'),
      dataIndex: 'applicationId',
      render: (id: string, enquiry: Enquiry) => (<LinkButton text={id} url={enquiry.applicationUrl} maxWidth={180} />),
      sorter: { compare: compareApplicationId },
    },
    {
      title: t('status.table.name'),
      dataIndex: 'name',
      render: (_: any, enquiry: Enquiry) => `${enquiry.firstName} ${enquiry.surname}`,
      sorter: { compare: compareName },
    },
    {
      title: t('status.table.last.updated'),
      dataIndex: 'evidenceProviders',
      render: (providers: EvidenceProvider[]) => (renderBookingStatusUpdated(providers)),
      sorter: { compare: compareUpdated },
    },
    {
      title: t('status.table.evidence.requested'),
      dataIndex: 'evidenceProviders',
      render: (providers: EvidenceProvider[]) => (renderEvidenceRequested(providers)),
      sorter: { compare: compareEvidenceRequested },
    },
    {
      title: t('status.table.evidence.provider'),
      dataIndex: 'evidenceProviders',
      render: (providers: EvidenceProvider[]) => (renderEvidenceProvider(providers)),
      sorter: { compare: compareEvidenceProvider },
    },
    {
      title: t('status.table.status'),
      dataIndex: 'evidenceProviders',
      render: (providers: EvidenceProvider[]) => (renderBookingStatus(providers)),
      sorter: { compare: compareBookingStatus },
    },
    {
      title: '',
      dataIndex: 'parentEnquiryId',
      render: (id: string, enquiry: Enquiry) => enquiryLinks(accessToken, enquiry, t, 'status.table.enquiry.', setBusy),
    },
  ];

  return (
    <div className="status-table">
      <EnquiryTable columns={columns} getEnquiries={getEnquiries} expandable accessToken={accessToken} />
      <div className="spinner">
        <Spin spinning={busy} />
      </div>
    </div>
  );
};

export default withAuthenticationRequired(StatusTable, {});
