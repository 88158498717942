import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { BaseOptionType } from 'rc-select/lib/Select';
import { useTranslation } from 'react-i18next';

import { closeEnquiry, getMedicalProviderOptions, startEnquiry } from './dao/RulesEngineDao';
import { createReferral } from './dao/ExtranetDao';
import { createBookingConfirmation, sendMedicalEvidence } from './dao/MedicalAdapterDao';
import { getTransactionForEnquiry } from './dao/EnquiryDao';

import { IdWrapper } from '../model/command';
import { TestEvidence } from '../model/testEvidence';
import { EnquiryOverrides } from '../model/EnquiryOverrides';
import { MedicalProviderOptions } from '../model/medicalProviderOptions';

function sleep(ms: number) {
  return new Promise<void>((resolve) => { setTimeout(() => resolve(), ms); });
}

//
// Create a new (test) enquiry
//
export const createTestEnquiry = (
  token: string,
  user: string,
  enquiryOverrides: EnquiryOverrides,
  forename: string,
  surname: string,
  branch: string,
  tag: string,
  delay: number,
): Promise<any> => startEnquiry(token, user, enquiryOverrides, branch, 'BREADTH_FIRST_ORDER', tag)
  .then((response: AxiosResponse<any>) => closeEnquiry(token, user, response.data.enquiryId))
  .then((response: AxiosResponse<any>) => {
    const timestamp = new Date().toISOString();
    const appId = `UME-${timestamp}`;
    return sleep(delay).then(() => createReferral(token, user, appId, response.data.enquiryId, forename, surname));
  })
  .catch((error: any) => {
    const { t } = useTranslation();
    message.error(`${t('testing.service.start.enquiry.error')} ${error}`);
    return Promise.reject(new Error(error));
  });

//
// Create a confirmed booking
//
export const bookingConfirmed = (token: string, enquiryId: string) => getTransactionForEnquiry(token, enquiryId)
  .then((response: AxiosResponse<IdWrapper>) => createBookingConfirmation(token, response.data.id))
  .catch((error: any) => {
    const { t } = useTranslation();
    message.error(`${t('testing.service.confirm.booking.error')} ${error}`);
    return Promise.reject(new Error(error));
  });

//
// Send medical evidence
//
export const sendEvidence = (token: string, enquiryId: string, testEvidence: TestEvidence) => getTransactionForEnquiry(token, enquiryId)
  .then((response: AxiosResponse<IdWrapper>) => sendMedicalEvidence(token, response.data.id, testEvidence))
  .catch((error: any) => {
    const { t } = useTranslation();
    message.error(`${t('testing.service.send.evidence.error')} ${error}`);
    return Promise.reject(new Error(error));
  });

//
// Get the medical evidence providers
//
export const getMedicalEvidenceProviders = (token: string, branch: string, tag: string) => getMedicalProviderOptions(token, branch, tag)
  .then((response: AxiosResponse<MedicalProviderOptions>) => {
    const options: BaseOptionType[] = [];
    response.data.options.forEach((option) => options.push({ label: option.text, value: option.name }));
    return Promise.resolve(options);
  })
  .catch((error: any) => {
    const { t } = useTranslation();
    message.error(`${t('testing.service.medical.providers.error')} ${error}`);
    return Promise.reject(new Error(error));
  });
