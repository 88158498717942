import axios, { AxiosResponse } from 'axios';
import Moment from 'moment';

import { getProviderId } from '../permissionService';
import { TestEvidence } from '../../model/testEvidence';

//
// Create a confirmed booking
//
export const createBookingConfirmation = (token: string, transactionId: string): Promise<AxiosResponse<void>> => {
  const providerId: string = getProviderId(token);

  return axios.put<void>(
    `/ma/v1/appointments/${transactionId}`,
    {
      transactionId,
      medProviderReference: 'Dummy medical provider reference',
      appointmentDateTime: Moment().add(1, 'days').format('YYYY-MM-DD hh:mm:ss'),
      action: 'ARRANGED',
    },
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

//
// Send medical evidence
//
export const sendMedicalEvidence = (token: string, transactionId: string, testEvidence: TestEvidence): Promise<AxiosResponse<void>> => {
  const providerId: string = getProviderId(token);

  const data: any = {
    metadata: JSON.parse(testEvidence.metadata),
    transactionId,
  };

  const formData = new FormData();

  if (!(testEvidence.filename === undefined) && !(testEvidence.fileContent === undefined)) {
    data.filename = testEvidence.filename;
    formData.append('report', testEvidence.fileContent);
  }
  formData.append('data', JSON.stringify(data));

  return axios.post<void>(
    `/ma/v1/reports/${transactionId}`,
    formData,
    {
      headers: {
        'X-ProviderId': providerId, // only for local testing, real users are extracted from token
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};
