import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Auth0Provider } from '@auth0/auth0-react';
import { Spin } from 'antd';

import { getEnvironmentalConfig } from './service/permissionService';
import { EnvironmentConfig } from './model/EnvironmentConfig';

import App from './App';

import './App.less';

//
// Wrapper component for the actual application, so that we can use lifecycle hooks to gather environmental information.
//
const AppLoader: FC = () => {
  const { t } = useTranslation();

  const [envLoading, setEnvLoading] = useState(true);
  const [env, setEnv] = useState<EnvironmentConfig>({
    domainName: '',
    clientId: '',
    audience: '',
    envName: '',
    umeUrl: '',
    enableTestingTab: false,
    enableCreateButton: false,
    enquiryCreationBranch: '',
    enquiryCreationTag: '',
    enquiryCreationDelay: 0,
  });

  useEffect(() => {
    getEnvironmentalConfig()
      .then((response: EnvironmentConfig) => {
        setEnv(response);
        setEnvLoading(false);
      });
  }, []);

  return (
    <div>
      {
        envLoading
          ? (
            <div className="app-loading">
              {t('app.loading')}
              <Spin />
            </div>
          )
          : (
            <Auth0Provider
              domain={env.domainName}
              clientId={env.clientId}
              redirectUri={window.location.origin}
              audience={env.audience}
              scope="openid email offline_access"
            >
              <App env={env} />
            </Auth0Provider>
          )
      }
    </div>
  );
};

export default AppLoader;
