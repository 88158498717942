import React, { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { TestEvidence } from '../../model/testEvidence';

import stockMetadata from '../data/medicalEvidence.json';

import './EditMedicalEvidenceDialog.less';
//
// Edit the medical evidence for an enquiry.
// The supplied onExit function will be called with the (edited) metadata if yes is clicked and false if not.
//

const EditMedicalEvidenceDialog: FC<{
  onExit: (response: TestEvidence | boolean) => boolean,
  open: boolean,
  name: string,
  provider?: string
}> = ({
  onExit, open, name, provider = 'Unknown',
}) => {
  const [testEvidence, setTestEvidence] = useState<TestEvidence>({ metadata: '' });
  const { t } = useTranslation();

  // <input> is always an uncontrolled component, and can only ever have its value programmatically set to an empty string,
  // so it can't be bound to a variable and our reset method need to clear the component manually
  const resetFileInput = () => {
    const input = document.getElementById('med-report-file');
    if (input) {
      // @ts-ignore
      input.value = '';
    }
  };

  const formatJson = (json: any) : string => JSON.stringify(json, null, 2);

  const handleReset = () => {
    setTestEvidence({ metadata: formatJson(stockMetadata) });
    resetFileInput();
  };

  const handleNo = () => {
    handleReset();
    onExit(false);
  };

  const handleYes = () => {
    const selectedEvidence = { ...testEvidence };
    if (onExit(selectedEvidence)) {
      handleReset();
    }
  };

  const handleFileSelect = (event: any) => {
    setTestEvidence({ metadata: testEvidence.metadata, filename: event.target.files[0].name, fileContent: event.target.files[0] });
  };

  const handleTextArea = (event: any) => {
    setTestEvidence({ metadata: event.target.value, filename: testEvidence.filename, fileContent: testEvidence.fileContent });
  };

  useEffect(() => {
    handleReset();
  }, []);

  return (
    <Modal
      title={t('edit.evidence.dialog.title')}
      onCancel={handleNo}
      open={open}
      destroyOnClose
      footer={[
        <Button key="reset" title={`${t('edit.evidence.dialog.button.reset.title')}`} onClick={handleReset}>
          {t('edit.evidence.dialog.button.reset')}
        </Button>,
        <Button key="no" title={`${t('edit.evidence.dialog.button.no.title')}`} onClick={handleNo}>
          {t('edit.evidence.dialog.button.no')}
        </Button>,
        <Button key="yes" type="primary" title={`${t('edit.evidence.dialog.button.yes.title')}`} onClick={handleYes}>
          {t('edit.evidence.dialog.button.yes')}
        </Button>,
      ]}
    >
      {t('edit.evidence.dialog.text')}
      <table className="edit-evidence-dialog-evidence-table">
        <tbody>
          <tr>
            <th>{t('edit.evidence.dialog.name')}</th>
            <td>{name}</td>
          </tr>
          <tr>
            <th>{t('edit.evidence.dialog.evidence.provider')}</th>
            <td>{provider}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <form className="edit-evidence-dialog-edit-evidence">
                <textarea value={testEvidence.metadata} rows={20} onChange={(event) => handleTextArea(event)} />
              </form>
            </td>
          </tr>
          <tr>
            <th>{t('edit.evidence.dialog.attach.report')}</th>
            <td><input type="file" id="med-report-file" onChange={(event) => handleFileSelect(event)} /></td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default EditMedicalEvidenceDialog;
