import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ModelTable from './ModelTable';
import UserPermissions from '../model/userPermissions';

const ModelConfigManagement: FC<{ permissions: UserPermissions }> = ({ permissions }) => {
  const { t } = useTranslation();

  return (
    <div className="model-config">
      <h2 className="tab-heading">{t('admin.task.model')}</h2>
      <ModelTable permissions={permissions} />
    </div>
  );
};

export default ModelConfigManagement;
