import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import TestingTable from './TestingTable';

import { EnvironmentConfig } from '../model/EnvironmentConfig';

const TestingTab: FC<{ env: EnvironmentConfig, accessToken: string }> = ({ env, accessToken }) => {
  const { t } = useTranslation();

  return (
    <div className="testing-tab">
      <h1 className="tab-heading">{t('testing.tab.title')}</h1>
      <TestingTable env={env} accessToken={accessToken} />
    </div>
  );
};

export default TestingTab;
