import jwtDecode, { JwtPayload } from 'jwt-decode';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { EnvironmentConfig } from '../model/EnvironmentConfig';
import { getEnvConfig } from './dao/EnvironmentConfigDao';

import UserPermissions from '../model/userPermissions';

// Permission names
const CAN_OVERRIDE = 'CAN_OVERRIDE_ENQUIRY';
const CAN_CREATE = 'CAN_CREATE_ENQUIRY';
const CAN_ACTION = 'CAN_ACTION_ENQUIRY';

const CAN_CREATE_PROVIDER_USER = 'CAN_CREATE_PROVIDER_USER';
const CAN_UPDATE_PROVIDER_USER = 'CAN_UPDATE_PROVIDER_USER';
const CAN_DELETE_PROVIDER_USER = 'CAN_DELETE_PROVIDER_USER';

const CAN_CREATE_SYSTEM_USER = 'CAN_CREATE_SYSTEM_USER';
const CAN_UPDATE_SYSTEM_USER = 'CAN_UPDATE_SYSTEM_USER';
const CAN_DELETE_SYSTEM_USER = 'CAN_DELETE_SYSTEM_USER';

// These are not used yet
const CAN_CREATE_PROVIDER_CONFIGURATION = 'CAN_CREATE_PROVIDER_CONFIGURATION';
const CAN_UPDATE_PROVIDER_CONFIGURATION = 'CAN_UPDATE_PROVIDER_CONFIGURATION';
const CAN_DELETE_PROVIDER_CONFIGURATION = 'CAN_DELETE_PROVIDER_CONFIGURATION';

const decodeToken = (token: string) => JSON.parse(JSON.stringify(jwtDecode(token)));

export const getPermissions = (token: string): UserPermissions => {
  const decodedAccessToken = decodeToken(token);
  const permissions = decodedAccessToken['https://underwriteme.co.uk/permissions'];

  return {
    permissions,
    canOverride: permissions.includes(CAN_OVERRIDE),
    canCreate: permissions.includes(CAN_CREATE),
    canAction: permissions.includes(CAN_ACTION),
    canSystemAdmin: permissions.includes(CAN_CREATE_SYSTEM_USER)
                    || permissions.includes(CAN_UPDATE_SYSTEM_USER)
                    || permissions.includes(CAN_DELETE_SYSTEM_USER),
    canOrgAdmin: permissions.includes(CAN_CREATE_PROVIDER_USER)
                  || permissions.includes(CAN_UPDATE_PROVIDER_USER)
                  || permissions.includes(CAN_DELETE_PROVIDER_USER),
    canCreateUser: permissions.includes(CAN_CREATE_SYSTEM_USER) || permissions.includes(CAN_CREATE_PROVIDER_USER),
    canDeleteUser: permissions.includes(CAN_DELETE_SYSTEM_USER) || permissions.includes(CAN_DELETE_PROVIDER_USER),
    canUpdateUser: permissions.includes(CAN_UPDATE_SYSTEM_USER) || permissions.includes(CAN_UPDATE_PROVIDER_USER),
  };
};

export const getLogin = (token: string): string => {
  const decodedAccessToken = decodeToken(token);
  return decodedAccessToken['https://underwriteme.co.uk/email'];
};

export interface Metadata {
  profile: string;
}

export interface Token extends JwtPayload {
  'https://underwriteme.co.uk/app_metadata': Metadata
  'https://underwriteme.co.uk/permissions': string[]
}

export const getProfile = (token: string) => {
  const decodedAccessToken = decodeToken(token);
  const { profile } = decodedAccessToken['https://underwriteme.co.uk/app_metadata'];
  return JSON.parse(profile);
};

export const getProviderId = (token: string): string => {
  const { providerId } = getProfile(token);
  return providerId;
};

export const getEnvironmentalConfig = (): Promise<EnvironmentConfig> => getEnvConfig()
  .then((response: AxiosResponse<EnvironmentConfig>) => Promise.resolve(response.data))
  .catch((error: any) => {
    const { t } = useTranslation();
    message.error(`${t('permission.service.getenv.error')} ${error}`);
    return Promise.reject(new Error(error));
  });
